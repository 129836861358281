<script setup lang="ts">
interface Props {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    items: any[]; // массив элементов
    className?: string | string[]; // дополнительный класс для стилизации из родительского компонента
    multiple?: boolean; // множественный выбор
    arrowTop?: boolean; // стрелка центрируется относительно верха
}

withDefaults(defineProps<Props>(), {
    steps: () => [],
    className: '',
    multiple: false,
    arrowTop: false,
});

const panel = ref([]);

const emit = defineEmits(['change']);

const changeHandler = () => {
    emit('change');
};
</script>

<template>
    <v-expansion-panels 
        v-model="panel"
        :class="['accordion', className, arrowTop ? 'arrow-top' : '']"
        :multiple="multiple"
        @update:modelValue="changeHandler"
    >
        <v-expansion-panel
            v-for="(item, index) in items"
            :key="index"
            :value="index"
            @click="panel"
        >
            <template #default>
                <v-expansion-panel-title>
                    <div class="accordion-header">
                        <slot 
                            name="title" 
                            :item="item" 
                            :index="index"
                        />
                    </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div class="accordion-content">
                        <slot 
                            name="text" 
                            :item="item" 
                            :index="index"
                        />
                    </div>
                </v-expansion-panel-text>
            </template>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<style scoped lang="scss">
.accordion {
    &-header {
        width: 100%;
    }

    &.arrow-top {
        :deep(.v-expansion-panel-title) {
            align-items: flex-start !important;
        }

        :deep(.v-expansion-panel-title__icon) {
            margin-top: 2px;
        }
    }

    :deep(.v-expansion-panel-title) {
        letter-spacing: 0;
    }

    :deep(.v-expansion-panel-text) {
        letter-spacing: 0;
    }
   
}
</style>