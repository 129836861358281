import type { Result } from "~/types/common/result";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';
import type { AcceptanceStatus } from '~/layers/settlement/types/realty';
import { AcceptanceStatuses } from '~/layers/settlement/constants/realty';
import type { IAcceptancesData } from "~/layers/settlement/types/acceptancesTable";
import type { IAcceptanceDialog, IAcceptanceDialogInfo, IAcceptanceDialogSend } from "~/layers/settlement/types/acceptance";
import moment from 'moment';
import type { IManagerAcceptanceGridFilter } from "~/layers/settlement/types/list/filter";
import { useAuthStore } from "~/stores/auth/useAuthStore";

export const useAcceptances = () => {
    const { t } = useI18n();

    const { get, post } = useApi();
    const { tryAction } = useSafe();
    const { userId, user } = useAuthStore();

    const config = useRuntimeConfig();

    /**
    * Получает полный объект для таблицы со всей информацией
    * @param filter Фильтр.
    * @param take Количество записей, которое необъодимо вернуть.
    * @param skip Количество записей, которое необходимо пропустить.
    */
    const getData = async (filter: IManagerAcceptanceGridFilter, take: number, skip: number) : Promise<Result<IAcceptancesData>> => {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/manager/list`;
            return await post<IAcceptancesData>(url, {...filter, take, skip });
        });
    };

    /**
    * Получает все данные для модального окна по выбранной приемке
    * @param acceptanceId Идентификатор заявки
    */
    const getFullAcceptanceInfo = async (acceptanceId: number) : Promise<Result<IAcceptanceDialog>> =>  {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/${acceptanceId}/detail`;
            return await get<IAcceptanceDialog>(url);
        });
    };

    /**
    * Отправляет обновленные данные по выбранной приемке
    * @param acceptanceId Идентификатор заявки
    * @param data Объект с редактируемыми полями
    */
    const updateAcceptanceInfo = async (acceptanceId: number, data: IAcceptanceDialogSend) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = `${config.public.settlementApiBaseUrl}/acceptances/${acceptanceId}/update`;
            return await post(url, data);
        });
    }

    /** получает все статусы ОН в виде массива с ключами и значениями */
    const getAcceptanceFilterStatuses = (): IStringKeyValueData[] => {
        const statuses: IStringKeyValueData[] = [
            ...getAcceptanceStatuses()
        ];
        return statuses;
    }

    /** получает все статусы ОН в виде массива с ключами и значениями */
    const getAcceptanceStatuses = (): IStringKeyValueData[] => {
        const statuses: IStringKeyValueData[] = [
            { type: 'Invited', name: t('settlement.acceptance_status_manager.invited') },
            { type: 'Appointmented', name: t('settlement.acceptance_status_manager.appointmented') },
            { type: 'Prepared', name: t('settlement.acceptance_status_manager.prepared') },
            { type: 'SuccessResult', name: t('settlement.acceptance_status_manager.successResult') },
            { type: 'ResultWithRemarks', name: t('settlement.acceptance_status_manager.resultWithRemarks') },
            { type: 'FailResult', name: t('settlement.acceptance_status_manager.failResult') },
            { type: 'Cancelled', name: t('settlement.acceptance_status_manager.cancelled') }
        ];
        return statuses;
    }

    /** форматирует дату в формат для отображения в таблице */
    const formatDate = (date: string | Date | null) : string => {
        if(!date) {
            return '';
        }

        return moment(date).format('DD MMMM') + ' &bull; ' + moment(date).format('HH:mm');
    }

    // получает формат документа при помощи названия файла
    const getFileExtension = (filename: string) : string => {
        const parts = filename.split('.');
    
        if (parts.length < 2 || parts[parts.length - 1] === '') {
            return '—';
        }
        
        return parts.pop()!.toLowerCase();;
    };

    /** форматирует серию паспорта в формат для отображения */
    const formatPassportSeries = (series: string) : string => {
        if (!series) {
            return '';
        }
        return `${series.slice(0, 2)} ${series.slice(2)}`;
    }

    /** генерирует поле для общей информации объекта */
    const createInfoArray = (data: IAcceptanceDialog, fullSquare: string, realtyTypeName: string) : IAcceptanceDialogInfo[] => {
        let array = [];
        if (!data || !data.realtyType && data.realtyType !== 0) {
            return [];
        }
    
        if (data.realtyType === 1) {
            array.push({
                icon: 'ri-door-closed-line',
                title: t('settlement.admin.acceptances.realty_info.realtyTypeName'),
                value: realtyTypeName,
            })
        }
    
        array.push(
            {
                icon: 'ri-square-line',
                title: t('settlement.admin.acceptances.realty_info.square'),
                value: fullSquare,
            },
            {
                icon: 'ri-stairs-line',
                title: t('settlement.admin.acceptances.realty_info.floorNumber'),
                value: data.floorNumber,
            },
            {
                icon: 'ri-paint-brush-line',
                title: t('settlement.admin.acceptances.realty_info.finishing'),
                value: data.isTypeFinish ? t('forms.shared.yesNo.yes') : t('forms.shared.yesNo.no'),
            },
            // временно убрано
            // {
            //     icon: 'ri-building-4-line',
            //     title: t('settlement.admin.acceptances.realty_info.projectName'),
            //     value: data.projectName,
            // },
            {
                icon: 'ri-hotel-line',
                title: t('settlement.admin.acceptances.realty_info.sectionNumber'),
                value: data.sectionNumber,
            },
        );
    
        return array;
    }

    /** Создает массив доступных менеджеров для селекта в модальном окне */
    const createManagerArray = (data: IAcceptanceDialog) : IStringKeyValueData[]=> {
        let array = [{
            type: '',
            name: t('forms.shared.dropdown.not_selected'),
        }];
    
        // Добавялем текущего пользователя
        if(userId) {
            array.push({
                type: userId,
                name: userHelper.getFullNameUser(user?.lastName, user?.firstName, '', user?.phoneNumber),
            });
        }

        if (!data) {
            return array;
        }
    
        // Добавялем выбранного менеджера
        if(data.managerId && data.managerId != userId) {
            array.push({
                type: data.managerId,
                name: data.fullNameManager ? data.fullNameManager : '',
            });
        }
    
        // Добавялем предыдущего менеджера
        if(data.lastManagerId && data.lastManagerId != userId) {
            array.push({
                type: data.lastManagerId,
                name: data.fullNameLastManager,
            });
        }
    
        return array;
    };

    /**  объект для модального окна приемки по умолчанию */
    const defaultAcceptanceData:IAcceptanceDialog = {
        realtyType: 0,
        address: '',
        number: '',
        acceptanceDate: '',
        schemeUrl: '',
        square: 0,
        acceptanceStatus: AcceptanceStatuses.Undefined,
        acceptanceId: 0,
        acceptanceStatusDescription: "",
        floorNumber: 0,
        isTypeFinish: false,
        sectionNumber: 0,
        // projectName: '', // временно убрано
        managerId: "",
        fullNameManager: "",
        lastManagerId: "",
        fullNameLastManager: "",
        accepter: "",
        technicalComment: "",
        remarks: "",
        owners: [],
    };

    /**  Возвращает ссылку на содержимое файла */
    const getFileContentUrl = (fileGuid: string) : string => {
        const config = useRuntimeConfig();
        return `${config.public.fileApiBaseUrl}/files/settlement/${fileGuid}/content`;
    }

    /**  Возвращает полный адрес */
    const getFullAddress = (
        address: string, 
        realtyType: number, 
        realtyTypeName: string,
        floorNumber: number,
        number: string
    ) : string => {
        return `${address}, ${t('settlement.common.floor')} ${floorNumber}, ${realtyTypeName} ${number}`;
    }

    return {
        getData,
        getAcceptanceFilterStatuses,
        getAcceptanceStatuses,
        formatDate,
        getFullAcceptanceInfo,
        updateAcceptanceInfo,
        getFileExtension,
        formatPassportSeries,
        createInfoArray,
        createManagerArray,
        defaultAcceptanceData,
        getFileContentUrl,
        getFullAddress,
    };
};